.menuItem {
	&:active,
	&:hover,
	&:focus {
		color: var( --wp--preset--color--red-700 ) !important;
	}
}
.sponsorButton {
	color: var( --wp--preset--color--red-800 ) !important;
}
